const websitesIndexData = {
    "GoParts": {
        "favicon": "gp_favicon_03",
        "title": "GoParts Peças Originais",
        "description": "Compre peças genuínas diretamente das maiores concessionárias do Brasil. Insira sua placa e veja peças compatíveis. Fiat, Renault, VW, Nissan, Toyota, Ford e muito mais.",
        "canonical": "https://goparts.com.br/",
        "gtm": "GTM-NS75HG24",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/goparts_2024_01.jpg',
        "MetaIcon180": 'gpIcon180.png',
        "facebookPixel": '2106068989891363'
    },
    "Itavema": {
        "favicon": "itavema",
        "title": "Loja Oficial Peças Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema. Peças Originais das marcas Fiat, Hyundai, Renault, Nissan, Toyota e Volvo.",
        "canonical": "https://pecas.itavema.com.br/",
        "gtm": "GTM-P8C2V8N",
        "g_tag": "G-RLRRTZ1WHN",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema.png',
        "facebookPixel": ''
    },
    "Savol": {
        "favicon": "savol",
        "title": "Peças Savol",
        "description": "Peças novas e genuínas diretamente das concessionárias Savol. Peças Originais das marcas Citroen, Fiat, Kia, Peugeot, Toyota e Volkswagen.",
        "canonical": "https://pecas.savol.com.br/",
        "gtm": "GTM-PJJ7WZQD",
        "g_tag": "G-M99WH1KRRY",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/savol.png',
        "facebookPixel": ''
    },
    "Vecol": {
        "favicon": "vecol",
        "title": "Peças Vecol",
        "description": "Peças novas e genuínas diretamente das concessionárias Vecol. Peças Originais Volkswagen.",
        "canonical": "https://pecas.vecolvw.com.br/",
        "gtm": "GTM-P7KVCGZS",
        "g_tag": "G-CZGWTX1L90",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/vecol.png',
        "facebookPixel": ''
    },
    "Vigorito": {
        "favicon": "vigorito",
        "title": "Peças Vigorito",
        "description": "Peças novas e genuínas diretamente das concessionárias Vigorito. Peças Originais das marcas Chevrolet, Kia, Nissan e Volkswagen.",
        "canonical": "https://vigoritopecas.com.br/",
        "gtm": "GTM-WV2CX9H2",
        "g_tag": "G-7MT8YF6ZPG",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/vigorito.png',
        "facebookPixel": ''
    },
    "Discautol": {
        "favicon": "discautol",
        "title": "Peças Discautol",
        "description": "Peças novas e genuínas diretamente das concessionárias Discautol. Peças Originais Volkswagen.",
        "canonical": "https://loja.discautol.com.br/",
        "gtm": "GTM-5KDLCMMG",
        "g_tag": "G-QBL33SJ06D",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/discautol.png',
        "facebookPixel": ''
    },
    "Iesa": {
        "favicon": "iesa",
        "title": "Peças Grupo IESA",
        "description": "Peças novas e genuínas diretamente das concessionárias do Grupo IESA.",
        "canonical": "https://loja.iesarenault.com.br/",
        "gtm": "GTM-MSHFBTGJ",
        "g_tag": "G-KTZRBNVVRW",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/iesa.png',
        "facebookPixel": ''
    },
    "Revemar": {
        "favicon": "revemar",
        "title": "Peças Revemar",
        "description": "Peças novas e genuínas diretamente das concessionárias Revemar. Peças Originais das marcas Citroen, Fiat, Ford, Jeep, Nissan, Peugeot, Renault e Volkswagen.",
        "canonical": "https://pecas.revemar.com.br/",
        "gtm": "GTM-NRW8648W",
        "g_tag": "G-454T8B12G7",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/revemar.png',
        "facebookPixel": ''
    },
    "Globo": {
        "favicon": "globo",
        "title": "Peças Grupo Globo",
        "description": "Peças novas e genuínas diretamente das concessionárias Grupo Globo. Peças Originais das marcas Ford, Jeep, Nissan e Renault.",
        "canonical": "https://loja.grupoglobo.com.br/",
        "gtm": "GTM-PJBCQZNB",
        "g_tag": "G-9ZRVBPWNHL",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/globo.png',
        "facebookPixel": ''
    },
    "Nissul": {
        "favicon": "nissul",
        "title": "Nissul Gala",
        "description": "Peças novas e genuínas diretamente das concessionárias Nissul. Peças Originais das marcas Hyundai, Jeep e Renault.",
        "canonical": "https://nissulgala.shop/",
        "gtm": "GTM-M4WK2C45",
        "g_tag": "G-7QHHF73VDK",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/nissul.png',
        "facebookPixel": ''
    },
    "ItavemaFiat": {
        "favicon": "itavemafiat",
        "title": "Peças Fiat Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema Fiat. Peças Originais Fiat.",
        "canonical": "https://pecasfiat.itavema.com.br/",
        "gtm": "GTM-M5QB7G4M",
        "g_tag": "G-M6S2Y0TMEQ",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema_fiat.png',
        "facebookPixel": ''
    },
    "ItavemaHyundai": {
        "favicon": "itavemahyundai",
        "title": "Peças Hyundai Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema Hyundai. Peças Originais Hyundai.",
        "canonical": "https://pecashyundai.itavema.com.br/",
        "gtm": "GTM-5578WTH7",
        "g_tag": "G-ZPRFCZ66YM",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema_hyundai.png',
        "facebookPixel": ''
    },
    "ItavemaRenault": {
        "favicon": "itavemarenault",
        "title": "Peças Renault Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema Renault. Peças Originais Renault.",
        "canonical": "https://pecasrenault.itavema.com.br/",
        "gtm": "GTM-WKGKTWFN",
        "g_tag": "G-CNQPCX4537",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema_renault.png',
        "facebookPixel": ''
    },
    "ItavemaToyota": {
        "favicon": "itavematoyota",
        "title": "Peças Toyota Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema Toyota Japan Parts. Peças Originais Toyota.",
        "canonical": "https://pecastoyota.itavema.com.br/",
        "gtm": "GTM-KWCZ2GT",
        "g_tag": "G-QC9G538L7G",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema_toyota.png',
        "facebookPixel": ''
    },
    "ItavemaVolvo": {
        "favicon": "itavemavolvo",
        "title": "Peças Volvo Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema Volvo. Peças Originais Volvo.",
        "canonical": "https://pecasvolvo.itavema.com.br/",
        "gtm": "GTM-WWVQTJ2",
        "g_tag": "G-896FSTLDR4",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema_volvo.png',
        "facebookPixel": ''
    },
    "ItavemaByd": {
        "favicon": "byd",
        "title": "Peças Byd Itavema",
        "description": "Peças novas e genuínas diretamente das concessionárias Itavema Volvo. Peças Originais Volvo.",
        "canonical": "https://pecasbyd.itavema.com.br/",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/itavema_byd.png',
        "facebookPixel": ''
    },
    "Belcar": {
        "favicon": "belcar",
        "title": "Peças Belcar",
        "description": "Peças novas e genuínas diretamente das concessionárias Belcar. Peças Originais Fiat, Mitsubishi e Volkswagen.",
        "canonical": "",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/belcar.png',
        "facebookPixel": ''
    },
    "Carlider": {
        "favicon": "carlider",
        "title": "Peças Carlider",
        "description": "Peças novas e genuínas diretamente das concessionárias Carlider.",
        "canonical": "pecas.carlider.com.br",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/carlider.png',
        "facebookPixel": ''
    },
    "Umuarama": {
        "favicon": "umuarama",
        "title": "Peças Umuarama",
        "description": "Peças novas e genuínas diretamente das concessionárias Umuarama.",
        "canonical": "https://loja.grupoumuarama.com.br/",
        "gtm": "GTM-TTPDTRJZ",
        "g_tag": "G-3JYRNVRJ33",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/umuarama.png',
        "facebookPixel": ''
    },
    "UmuaramaHD": {
        "favicon": "umuaramahd",
        "title": "Peças Umuarama Harley Davidson",
        "description": "Peças originais Harley Davidson diretamente das concessionárias Umuarama HD.",
        "canonical": "https://loja.umuaramahd.com.br/",
        "gtm": "GTM-WZR4CSJ6",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '<meta name="google-site-verification" content="ajdxtENqNOwEgjRdCURa7sV6M0R94iPUOBC6PtXcrnk" />',
        "social_imgUrl": 'https://goparts.com.br/static/umuaramaHD.png',
        "facebookPixel": ''
    },
    "Apia": {
        "favicon": "apia",
        "title": "Peças Ápia VW",
        "description": "Peças novas e genuínas diretamente das concessionárias Ápia VW.",
        "canonical": "http://apiavolks.com.br/",
        "gtm": "GTM-PTV73T42",
        "g_tag": "G-ZQG4LDHFNV",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/apia.png',
        "facebookPixel": ''
    },
    "Duvel": {
        "favicon": "duvel",
        "title": "Peças Duvel",
        "description": "Peças novas e genuínas diretamente das concessionárias Duvel.",
        "canonical": "",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/duvel.png',
        "facebookPixel": ''
    },
    "Alta": {
        "favicon": "alta",
        "title": "Peças Alta VW",
        "description": "Peças novas e genuínas diretamente das concessionárias Alta VW.",
        "canonical": "",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/altavw.png',
        "facebookPixel": ''
    },
    "Luson": {
        "favicon": "luson",
        "title": "Peças Luson",
        "description": "Peças novas e genuínas diretamente das concessionárias Luson Volkswagen.",
        "canonical": "https://lojaluson.com.br/",
        "gtm": "GTM-K6NVQ3Q3",
        "g_tag": "G-GEC6JKGP59",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/luson.png',
        "facebookPixel": ''
    },
    "Open": {
        "favicon": "open",
        "title": "Peças Grupo Open",
        "description": "Peças novas e genuínas diretamente das concessionárias do Grupo Open. Hyundai, Renault e Mitsubishi",
        "canonical": "https://pecasgrupoopen.com.br/",
        "gtm": "GTM-T6R2BVH7",
        "g_tag": "G-83MTC7NX98",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/open.png',
        "facebookPixel": ''
    },
    "Faberge": {
        "favicon": "faberge",
        "title": "Peças Grupo Faberge",
        "description": "Peças novas e genuínas diretamente das concessionárias do Grupo Faberge.",
        "canonical": "https://pecas.grupofaberge.com.br/",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/faberge.png',
        "facebookPixel": ''
    },
    "Topcar": {
        "favicon": "topcar",
        "title": "Peças Audi Top Car",
        "description": "Peças novas e genuínas diretamente das concessionárias Audi Top Car.",
        "canonical": "https://pecas.auditopcar.com.br/",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/topcar.png',
        "facebookPixel": ''
    },
    "Pampa": {
        "favicon": "vw",
        "title": "Peças VW Pampa",
        "description": "Peças novas e genuínas diretamente das concessionárias Pampa Volkswagen.",
        "canonical": "https://pecas.pampaveiculos.com.br/",
        "gtm": "",
        "g_tag": "",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/pampa.jpg',
        "facebookPixel": ''
    },
    "UmuaramaMotos": {
        "favicon": "umuarama",
        "title": "Peças Umuarama Motos",
        "description": "Peças novas e genuínas diretamente das concessionárias Umuarama Harley Davidson, KTM e Triumph.",
        "canonical": "https://loja.umuaramamotos.com.br/",
        "gtm": "",
        "g_tag": "",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/umuaramaMotos.png',
        "facebookPixel": ''
    },
    "Estilo": {
        "favicon": "vw",
        "title": "Peças Estilo Volkswagen",
        "description": "Peças novas e genuínas diretamente das concessionárias Estilo Volkswagen.",
        "canonical": "pecasestiloautomoveis.com.br",
        "gtm": "GTM-T2VBJ9T7",
        "g_tag": "G-YHSMELRZX8",
        "googleTag": '<meta name="google-site-verification" content="9DkqRK2PQOgdyszzonEmJLPJiNXy8iBKWhmzXZoHrxU" />',
        "social_imgUrl": 'https://goparts.com.br/static/estilo.png',
        "facebookPixel": ''
    },
    "Provence": {
        "favicon": "provence",
        "title": "Peças Grupo Provence",
        "description": "Peças novas e genuínas diretamente das concessionárias do Grupo Provence.",
        "canonical": "provenceparts.com.br",
        "gtm": "GTM-5GG9JDS2",
        "g_tag": "G-BSVB430W0J",
        "googleTag": '<meta name="google-site-verification" content="1LUdl6nAx4aW8U2lYGzwPfd-oDTHEJrwJ7HETy_bTIw" />',
        "social_imgUrl": 'https://goparts.com.br/static/provence.png',
        "facebookPixel": ''
    },
    "G4R": {
        "favicon": "g4r",
        "title": "Peças G4R",
        "description": "Peças novas e genuínas diretamente das concessionárias do Grupo G4R.",
        "canonical": "pecas.g4r.com.br",
        "gtm": "GTM-5LTS93PR",
        "g_tag": "G-WZBSL8LQ4N",
        "googleTag": '<meta name="google-site-verification" content="0S5dRZ2RWIA_9xQ71cB5oc5-sq5mmK51lH1AS1V9TIk" />',
        "social_imgUrl": 'https://goparts.com.br/static/g4r.png',
        "facebookPixel": ''
    },
    "Barao": {
        "favicon": "barao",
        "title": "Peças Kia Barão",
        "description": "Peças novas e genuínas diretamente da concessionária Kia Barão.",
        "canonical": "pecaskia.com.br",
        "gtm": "GTM-KWHMJ49W",
        "g_tag": "G-2RD9993F9E",
        "googleTag": '<meta name="google-site-verification" content="5C4KajebWNehQkomK6lYkZTwfPlUNdc92hie0GLPLGc" />',
        "social_imgUrl": 'https://goparts.com.br/static/pecaskia.jpg',
        "facebookPixel": ''
    },
    "Recreio": {
        "favicon": "vw",
        "title": "Peças Recreio VW Belo Horizonte",
        "description": "Peças novas e genuínas diretamente das concessionárias Recreio Volkswagen Belo Horizonte.",
        "canonical": "pecasbh.recreiovw.com.br",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/recreio.png',
        "facebookPixel": ''
    },
    "Akta": {
        "favicon": "akta",
        "title": "Peças Akta",
        "description": "Peças novas e genuínas diretamente das concessionárias do Grupo Akta.",
        "canonical": "aktapecas.com.br",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/akta.png',
        "facebookPixel": ''
    },
    "BelcarFiat": {
        "favicon": "belcarfiat",
        "title": "Peças Belcar Fiat",
        "description": "Peças novas e genuínas diretamente das concessionárias Belcar Fiat.",
        "canonical": "belcarfiatpecas.com.br",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/belcarfiat.png',
        "facebookPixel": ''
    },
    "Asuka": {
        "favicon": "mitsubishi",
        "title": "Peças Asuka Mitsubishi",
        "description": "Peças novas e genuínas diretamente das concessionárias Asuka Mitsubishi.",
        "canonical": "asukapecas.com.br",
        "gtm": "GTM-XXXXXXX",
        "g_tag": "G-XXXXXXXXXX",
        "googleTag": '',
        "social_imgUrl": 'https://goparts.com.br/static/asuka.png',
        "facebookPixel": ''
    },
    "NissulYamaha": {
        "favicon": "yamaha",
        "title": "Peças Nissul Yamaha",
        "description": "Peças novas e genuínas diretamente das concessionárias Nissul Yamaha.",
        "canonical": "https://pecas.nissulyamaha.com.br/",
        "social_imgUrl": 'https://goparts.com.br/static/nissulyamaha.png',
        "facebookPixel": ''
    },
    "BelcarYamaha": {
        "favicon": "yamaha",
        "title": "Peças Belcar Yamaha",
        "description": "Peças novas e genuínas diretamente das concessionárias Belcar Yamaha.",
        "canonical": "https://belcarmotospecas.com.br/",
        "social_imgUrl": 'https://goparts.com.br/static/belcaryamaha.png',
        "facebookPixel": ''
    },
}

module.exports = websitesIndexData;