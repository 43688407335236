import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import '../../Home/Home.css';
import './BannerSlider.css';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../../reducers/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/bundle';
import banner01_img from '../../images/mainBG_01.webp';
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import Logo from '../Logo';
import Button from '../Button';
import bannerVideo01 from '../../images/GoParts_24_BG_01_4mpbs.mp4';
import bannerVideo01Fallback from '../../images/banner_vid_preview_01.jpg';
import roadBg_01 from '../../images/home_road_bg_01.jpg';
import roadBg_02 from '../../images/home_road_bg_02.jpg';
import humanBg_01 from '../../images/home_humans_bg_01.jpg';
import humanBg_02 from '../../images/home_humans_bg_02.jpg';
import partsBg_01 from '../../images/home_parts_bg_01.jpg';
import magentaBg_01 from '../../images/home_magentaBg_01.jpg';
import goparts_logo_01 from '../../images/about_GoParts_bg_03.jpg';
import GenericVehicle from '../../images/0.png';

import { ChevronRight, KeyboardDoubleArrowUp, Search, Edit, KeyboardArrowDown } from '@mui/icons-material';
import { AddCar } from '../../Utilities/Icons';
import { AddShoppingCart } from '@mui/icons-material';

import carBanner from  '../../images/car_homeBanner_01.webp';
import partsBanner from  '../../images/steps_part_01.webp';
import boxGpBanner from  '../../images/steps_GP_box_01.webp';

import GPlogoPatternBG_01 from '../../images/GoParts_Logos_Pattern_01.jpg';
import { BrandsListSVG, HomologBrandsSVG } from '../../Utilities/BrandsList';
import { storeInfo } from '../../Utilities/Placeholders';
import { BrandsList } from '../../Utilities/BrandsList';
import { ModelImgs } from '../../Utilities/ModelImgs';

import { contentData, configData } from '../../Whitelabel/websitesData';

import harleySmallWhiteLogo from '../../images/UmuaramaMotos/harley_logo_small_white.png';
import ktmWhiteLogo from '../../images/UmuaramaMotos/ktm_logo_white.png';
import triumphWhiteLogo from '../../images/UmuaramaMotos/triumph_logo_white.png';


function BannerSlider() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { title, content, link } = storeInfo.aboutUs;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAboutUsClick = () => {
        window.open(`${link}`, '_blank');
    }

    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion, modelSeries } = useSelector(state => state.vehicle);

    const modelSeriesImg = (modelId) => {
        return ModelImgs[modelId] || GenericVehicle;
    };

    const openVehicleSelect = () => {
        //console.log('version: ', selectedVersion);
        if (configData.brands.length === 1 && !selectedVersion) {
            vehicleSelectWithBrand(configData.brands[0]);
        } else {
            if (selectedVersion) {
                if (configData.defaultStore === 'NissulYamaha' || configData.defaultStore === 'BelcarYamaha') {
                    navigate('/produtos/Peças');
                } else {
                    navigate('/categorias');
                }
            } else {
                dispatch(setIsSelectingVehicle(!isSelectingVehicle));
            }
        }
    };

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    const vehicleSelectWithBrand = (brand) => {
        dispatch(setSelectedVersion(''));
        dispatch(setSelectedModel(''));
        dispatch(setSelectedYear(''));
        dispatch(setSelectedBrand(brand));
        dispatch(setIsSelectingVehicle(true));
    }

    const openVehicleSelectOnly = () => {
        dispatch(setIsSelectingVehicle(true));
    }

    return (
        <div>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay ]}
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{
                    delay: 15000,
                    disableOnInteraction: false,
                }}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
            >

                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>

                {configData.defaultStore === 'GoParts' 
                    ?
                        <>
                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${bannerVideo01Fallback})`}}>
                                {/* <div className='bannerContainer'>
                                    <video autoPlay muted loop className='bannerVideo' poster={bannerVideo01Fallback}>
                                        <source src={bannerVideo01} type="video/mp4" />
                                    </video> */}

                                    {!selectedVersion &&
                                        <div className='titleContainer not500' style={{flexDirection: 'column'}}>
                                            <h1><mark>1.</mark> Selecione seu veículo</h1>
                                            <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                            <h1><mark>3.</mark> Compre e receba</h1>
                                        </div>
                                    }

                                    {!selectedVersion &&
                                        <div className='only500'>
                                            <h1>Selecione seu veículo:</h1>
                                        </div>
                                    }
                                    
                                    {selectedVersion &&
                                        <h1>Veículo selecionado:</h1>
                                    }
                                    
                                    {!selectedVersion
                                        ?
                                            <div className='column center' style={{gap: '10px'}}>
                                                <div className='bannerRowBrands'>
                                                    {configData.brands.map((item) => {
                                                        const brandName = item.charAt(0).toUpperCase() + item.slice(1);
                                                        const imageSrc = BrandsList[brandName];
                                                        return imageSrc ? (
                                                        <div
                                                            className='headerFilterOption'
                                                            key={item}
                                                            onClick={() => vehicleSelectWithBrand(item)}
                                                        >
                                                            <img className='headerFilterBrandLogo' src={imageSrc} alt={item} />
                                                        </div>
                                                        ) : (
                                                        <div className='headerFilterOption' style={{ color: 'white' }} key={item}>
                                                            {item}
                                                        </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className='column center' style={{gap: '6px'}}>
                                                    <span>ou</span>
                                                    <Button
                                                        onClick={openVehicleSelectOnly}
                                                        className="buttonColorThird smallButton"
                                                        label="Encontre pela placa"
                                                        iconStart={<Search/>}
                                                    />
                                                </div>
                                            </div>
                                        :
                                            <div className='column center' style={{gap: '20px'}}>
                                                <div className='row selectedVehicleHomeBanner' onClick={openVehicleSelectOnly}>
                                                    <img className='catVehicleImg' src={modelSeriesImg(modelSeries)}/>
                                                    <span style={{alignItems: 'center', whiteSpace: 'wrap'}} >{selectedModel} {selectedYear} {selectedVersion}</span>
                                                    <a style={{whiteSpace: 'nowrap'}}><Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                                                </div>
                                                <Button
                                                    className='giantButton'
                                                    iconStart={<span><AddShoppingCart style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                    label='Peças compatíveis'
                                                    onClick={openVehicleSelect}
                                                />
                                            </div>
                                    }
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                {/* <div className='defaultBannerBG' style={{backgroundColor: 'var(--secondColor)'}}> */}
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${humanBg_02})`}}>

                                    {/* <img src={Logo} className='BannerLogo'/> */}

                                    <div className='stepsBannerRow'>
                                        <img src={carBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={partsBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={boxGpBanner} className='bannerStepsImg'/>
                                    </div>

                                    <div className='titleContainer'>
                                        <h1><mark>1.</mark> Selecione seu veículo</h1>
                                        <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                        <h1><mark>3.</mark> Compre e receba</h1>
                                    </div>

                                    {/* <h1>Autopeças <mark>novas e origniais</mark> compatíveis com <mark>seu veículo</mark> e enviadas diretamente pelas <mark>concessionárias</mark>.</h1> */}
                                    
                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                    <div className='row center'style={{gap: '10px', marginBottom: '20px'}}>
                                        <Button
                                            className='giantButton'
                                            iconStart={<span><AddCar style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                            label={!selectedVersion ? 'Selecionar veículo' : 'Peças compatíveis'}
                                            onClick={openVehicleSelect}
                                        />

                                        {/* <Button
                                            className='buttonStyle colorButtonMain largeButton'
                                            label='Encontrar peças'
                                        /> */}
                                    </div>


                                    {/* <div className='stepsBannerRow'>
                                        <img src={carBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={partsBanner} className='bannerStepsImg'/>
                                        <ChevronRight/>
                                        <img src={boxGpBanner} className='bannerStepsImg'/>
                                    </div> */}

                                </div>
                            </SwiperSlide>
                            
                            <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${partsBg_01})`}}>

                                    <div className='titleContainer'>
                                        <h1>Autopeças <mark>novas e genuínas</mark> de concessionárias de todo o Brasil:</h1>
                                    </div>

                                    <div className='column center' style={{width: '100%'}}>
                                        {/* <h1 style={{padding: '20px'}}>Autopeças novas e genuínas das maiores montadoras e fabricantes homologados:</h1> */}
                                        <div className='row' style={{gap: '6px', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '800px', paddingInline: '5px'}}>
                                            {Object.entries(BrandsListSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBanner' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                        <div className='row' style={{columnGap: '20px', flexWrap: 'wrap', justifyContent: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.3)', padding: '20px 0', marginTop: '20px'}}>
                                            {Object.entries(HomologBrandsSVG).map(([brandName, brandLogo]) => (
                                                <img className='brandLogosBannerWide' key={brandName} src={brandLogo} alt={brandName} />
                                            ))}
                                        </div>
                                        <Button
                                            className='buttonColorSecond smallButton'
                                            label={storeInfo.aboutUs.linkCta}
                                            onClick={handleAboutUsClick}
                                        />
                                    </div>

                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide>
                            
                            {/* <SwiperSlide>
                                <div className='defaultBannerBG' style={{backgroundImage: `url(${goparts_logo_01})`}}>

                                    <div className='column center' style={{width: '100%', maxWidth: '800px', gap: '20px'}}>
                                        <div className='titleContainer'>
                                            <h1>{content[0]}</h1>
                                        </div>
                                        <span className='aboutUsBannerSpan'>{content[1]}</span>
                                        <div className='row center' style={{gap: '10px'}}>
                                            <Button
                                                className='buttonColorSecond smallButton'
                                                label={storeInfo.aboutUs.linkCta}
                                                onClick={handleAboutUsClick}
                                            />
                                            <Button
                                                className='buttonColorThird smallButton'
                                                label='Comprar peças'
                                                onClick={openVehicleSelect}
                                            />
                                        </div>
                                    </div>

                                    {!selectedVersion &&
                                        <div className='startHereContainer'>
                                            <div className='startHere' onClick={openVehicleSelect}>
                                                <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                <span>Comece aqui</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide> */}
                        </>
                    :
                        <>
                            {configData.defaultStore === 'UmuaramaMotos'
                                ?
                                <>
                                    <SwiperSlide>
                                        <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner01})`}}>
                                            <div className='column center' style={{justifyContent: 'center', alignItems: 'center', gap: '20px', textAlign: 'center', paddingInline: '20px', marginTop: '40px'}}>
                                                {/* <img className='BannerLogo' src={contentData.bannerLogoWhite} alt={`${configData.defaultStore} logo`} loading='lazy'/>  */}
                                                <div className='row center' style={{gap: '20px', justifyContent: 'center', alignItems: 'center'}}>
                                                    <img className='harleyBLogo' src={harleySmallWhiteLogo} alt='Harley-Davidson logo'/>
                                                    <img className='ktmBLogo' src={ktmWhiteLogo} alt='KTM logo'/>
                                                    <img className='triumphBLogo' src={triumphWhiteLogo} alt='Triumph logo'/>
                                                </div>
                                                <h2 className='bannerHighlightText' dangerouslySetInnerHTML={{ __html: contentData.bannerHighlight }} />
                                                <Button
                                                    className='giantButton'
                                                    // iconStart={<img src={harleySmallWhiteLogo} alt='Harley-Davidson' style={{height: '20px'}}/>}
                                                    label='Comprar peças'
                                                    onClick={() => navigate('/categorias')}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner02})`}}>
                                            <div className='column center' style={{justifyContent: 'center', alignItems: 'center', gap: '20px', textAlign: 'center', paddingInline: '20px', marginTop: '40px'}}>
                                                <img className='BannerLogo' src={ktmWhiteLogo} alt='KTM logo' loading='lazy'/> 
                                                <h2 className='bannerHighlightText'>Peças originais KTM</h2>
                                                <Button
                                                    className='giantButton'
                                                    label='Comprar peças KTM'
                                                    onClick={() => navigate('/produtos/KTM')}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner03})`}}>
                                            <div className='column center' style={{justifyContent: 'center', alignItems: 'center', gap: '20px', textAlign: 'center', paddingInline: '20px', marginTop: '40px'}}>
                                                <img className='BannerLogo' src={triumphWhiteLogo} alt='Triumph logo' loading='lazy'/> 
                                                <h2 className='bannerHighlightText'>Peças originais Triumph</h2>
                                                <Button
                                                    className='giantButton'
                                                    // className='giantButton disabledButton'
                                                    // label='Em breve'
                                                    label='Comprar peças Triumph'
                                                    onClick={() => navigate('/produtos/Triumph')}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </>
                                :
                                <>
                                {configData.brands.length === 1
                                    ?
                                        <>
                                            {configData.defaultStore !== 'Peças Kia'
                                                ?
                                                    <SwiperSlide>
                                                        <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner01})`}}>
            
                                                            <div className='column center' style={{justifyContent: 'center', alignItems: 'center', gap: '20px', textAlign: 'center', paddingInline: '20px'}}>
                                                                {(!selectedVersion || configData.noVehicle) &&
                                                                    <img className='BannerLogo' src={contentData.bannerLogoWhite} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
                                                                }
                                                                <h2 className='bannerHighlightText' dangerouslySetInnerHTML={{ __html: contentData.bannerHighlight }} />
                                                            </div>
            
                                                            {/* {!selectedVersion &&
                                                                <div className='titleContainer' style={{flexDirection: 'column'}}>
                                                                    <h1><mark>1.</mark> Selecione seu veículo</h1>
                                                                    <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                                                    <h1><mark>3.</mark> Compre e receba</h1>
                                                                </div>
                                                            } */}
                                                            
                                                            {!selectedVersion
                                                            ?
                                                                <div className='column center' style={{gap: '10px'}}>
                                                                    <Button
                                                                        className='giantButton'
                                                                        iconStart={<span><AddCar style={{position: 'relative', zIndex: 1}}/></span>}
                                                                        label='Selecionar veículo'
                                                                        onClick={openVehicleSelect}
                                                                    />
                                                                </div>
                                                            :
                                                                <div className='column center' style={{gap: '20px'}}>
                                                                    {!configData.noVehicle &&
                                                                        <div className='row selectedVehicleHomeBanner' onClick={openVehicleSelectOnly}>
                                                                            <img className='catVehicleImg' src={modelSeriesImg(modelSeries)}/>
                                                                            <span style={{alignItems: 'center', whiteSpace: 'wrap'}} >{selectedModel} {selectedYear} {selectedVersion}</span>
                                                                            <a style={{whiteSpace: 'nowrap'}}><Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                                                                        </div>
                                                                    }
                                                                    <Button
                                                                        className='giantButton'
                                                                        iconStart={<span><AddShoppingCart style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                        label={configData.noVehicle ? 'Comprar peças' : 'Peças compatíveis'}
                                                                        onClick={openVehicleSelect}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </SwiperSlide>
                                                :
                                                    <SwiperSlide>
                                                        <div className='defaultBannerBG' style={{backgroundImage: `url(${ !isMobile ? contentData.banner01 : contentData.banner06})`}}>
                                                            <div className='rowToColumn' style={{gap: '40px'}}>
                                                                <img className='BannerLogoLarge' src={contentData.bannerLogoWhite} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
                                                                
                                                                {!selectedVersion
                                                                    ?
                                                                        <div className='column center' style={{gap: '20px'}}>
                                                                            <div className='titleContainer' style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                                                                <h1><mark>1.</mark> Selecione seu veículo</h1>
                                                                                <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                                                                <h1><mark>3.</mark> Compre e receba</h1>
                                                                            </div>
                                                                            <Button
                                                                                className='giantButton'
                                                                                iconStart={<span><AddCar style={{position: 'relative', zIndex: 1}}/></span>}
                                                                                label='Selecionar veículo'
                                                                                onClick={openVehicleSelect}
                                                                            />
                                                                        </div>
                                                                    :
                                                                        <div className='column center' style={{gap: '20px'}}>
                                                                            <div className='row selectedVehicleHomeBanner' onClick={openVehicleSelectOnly}>
                                                                                <img className='catVehicleImg' src={modelSeriesImg(modelSeries)}/>
                                                                                <span style={{alignItems: 'center', whiteSpace: 'wrap'}} >{selectedModel} {selectedYear} {selectedVersion}</span>
                                                                                <a style={{whiteSpace: 'nowrap'}}><Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                                                                            </div>
                                                                            <Button
                                                                                className='giantButton'
                                                                                iconStart={<span><AddShoppingCart style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                                label= 'Peças compatíveis'
                                                                                onClick={openVehicleSelect}
                                                                            />
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                            }

                                            {configData.defaultStore !== 'NissulYamaha' && configData.defaultStore !== 'BelcarYamaha'
                                            ?
                                                <SwiperSlide>
                                                    <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner02})`}}>
    
                                                        <div className='stepsBannerRow'>
                                                            <img src={contentData.car123 === '' ? carBanner : contentData.car123} className='bannerStepsImg'/>
                                                            <ChevronRight/>
                                                            <img src={partsBanner} className='bannerStepsImg'/>
                                                            <ChevronRight/>
                                                            <img src={contentData.box123 === '' ? boxGpBanner : contentData.box123} className='bannerStepsImg'/>
                                                        </div>
        
                                                        <div className='titleContainer' style={{display: 'flex', flexDirection: 'column', gap: configData.defaultStore === 'Peças Kia' ? '10px' : '0'}}>
                                                            <h1><mark>1.</mark> Selecione seu veículo</h1>
                                                            <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                                            <h1><mark>3.</mark> Compre e receba</h1>
                                                        </div>
                                                        
                                                        {!selectedVersion &&
                                                            <div className='column center' style={{gap: '10px'}}>
                                                                <Button
                                                                    className='giantButton'
                                                                    iconStart={<span><AddCar style={{position: 'relative', zIndex: 1}}/></span>}
                                                                    label='Selecionar veículo'
                                                                    onClick={openVehicleSelect}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </SwiperSlide>
                                            :
                                                <SwiperSlide>
                                                    <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner02})`}}>
                                                        <div className='column center' style={{gap: '10px'}}>
                                                            <h1 style={{fontSize: '36px'}}>Tudo em Yamaha</h1>
                                                            <h2 className='bannerHighlightText'>Confira nossa linha completa de peças <mark>genuínas Yamaha</mark></h2>
                                                            <Button
                                                                className='giantButton'
                                                                iconStart={<span><AddShoppingCart style={{position: 'relative', zIndex: 1}}/></span>}
                                                                label='Tudo em Yamaha'
                                                                onClick={() => navigate('/produtos/Peças')}
                                                            />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            }
                                        </>
                                    :
                                        <>
                                            {configData.defaultStore === 'Vigorito' &&
                                                <SwiperSlide>
                                                    <div className='defaultBannerBG' style={{ backgroundImage: `url(${isMobile ? contentData.bannerSpecialMobile : contentData.bannerSpecial})`, backgroundColor: 'var(--mainColor)' }}>
                                                        <div className='rowToColumn' style={{columnGap: '20px', rowGap: '10px'}}>
                                                            <img style={{maxHeight: !isMobile ? '380px' : '320px'}} src={isMobile ? contentData.imgSepcialMobile : contentData.imgSepcial} alt={`${isMobile ? contentData.imgSepcialMobile : contentData.imgSepcial} logo`} loading='lazy' />
                                                            <div className='column center' style={{textAlign: 'center', gap: '10px'}}>
                                                                {!isMobile &&
                                                                    <img className='BannerLogo' src={contentData.mainLogo} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
                                                                }
                                                                <div className='column center' style={{textAlign: 'center', paddingInline: '10px'}}>
                                                                    <h2 style={{margin: '0', fontWeight: 'bold', fontSize: isMobile ? '16px' : '21px'}}> Segurança | Garantia</h2>
                                                                    {/* <span style={{margin: '0', color: 'var(--grayedText)'}}>*na grande SP</span> */}
                                                                    {/* <h2 style={{margin: '0', fontWeight: 'bold', fontSize: isMobile ? '16px' : '21px'}}>Entrega em 24h úteis* | Segurança | Garantia</h2> */}
                                                                </div>
                                                                <Button
                                                                    className='giantButton'
                                                                    iconStart={<span><AddCar style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                    label={!selectedVersion ? 'Selecionar veículo' : 'Peças compatíveis'}
                                                                    onClick={openVehicleSelect}
                                                                />
                                                            </div>
                                                        </div>
                                                        {!selectedVersion &&
                                                            <div className='startHereContainer'>
                                                                <div className='startHere' onClick={openVehicleSelect}>
                                                                    <KeyboardDoubleArrowUp style={{color: 'var(s--secondColor)'}}/>
                                                                    <span>Comece aqui</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </SwiperSlide>
                                            }

                                            {/* {configData.defaultStore === 'Discautol' &&
                                                <SwiperSlide>
                                                    <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.bannerSpecial})`}}>
        
                                                        <div className='column center' style={{justifyContent: 'center', alignItems: 'center', gap: '20px', textAlign: 'center', paddingInline: '20px'}}>
                                                            <img className='BannerLogoHuge' src={contentData.imgSepcial} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
                                                            <span>Promoção válida até 22/03/2025.</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            } */}

                                            {configData.defaultStore === 'Carlider' &&
                                                <>
                                                    <SwiperSlide>
                                                        <div className='defaultBannerBG' style={{ backgroundImage: `url(${contentData.banner01})`, backgroundColor: 'var(--mainColor)' }}>
                                                            <div className='rowToColumn' style={{columnGap: '20px', rowGap: '10px'}}>
                                                                <h2 style={{margin: '0', marginInline: isMobile ? '20px' : '0', maxWidth: '450px', fontSize: isMobile ? '20px' : '24px', textAlign: isMobile ? 'center' : 'left'}}>Encontre aqui peças <span style={{fontWeight: 'bold'}}>novas e genuínas</span> para o seu carro com a empresa que você já confia!</h2>
                                                                <img style={{maxHeight: !isMobile ? '300px' : '210px'}} src={isMobile ? contentData.imgSepcialMobile : contentData.imgSepcial} alt={`${isMobile ? contentData.imgSepcialMobile : contentData.imgSepcial} logo`} loading='lazy' />
                                                                <div className='column center' style={{textAlign: 'center', gap: '10px'}}>
                                                                    {/* {!isMobile &&
                                                                        <img className='BannerLogo' src={contentData.bannerLogoWhite} alt={`${configData.defaultStore} logo`} loading='lazy'/> 
                                                                    } */}
                                                                    <div className='titleContainer' style={{flexDirection: 'column'}}>
                                                                        <h1><mark>1.</mark> Selecione seu veículo</h1>
                                                                        <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                                                        <h1><mark>3.</mark> Compre e receba</h1>
                                                                    </div>
                                                                    <Button
                                                                        className='giantButton'
                                                                        iconStart={<span><AddCar style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                        label={!selectedVersion ? 'Selecionar veículo' : 'Peças compatíveis'}
                                                                        onClick={openVehicleSelect}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {!selectedVersion &&
                                                                <div className='startHereContainer'>
                                                                    <div className='startHere' onClick={openVehicleSelect}>
                                                                        <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                                        <span>Comece aqui</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </SwiperSlide>

                                                    <SwiperSlide>
                                                        <div className='defaultBannerBG' style={{ backgroundImage: `url(${contentData.banner02})`, backgroundColor: 'var(--mainColor)' }}>
                                                            <h2 style={{margin: '0', marginInline: '20px', fontSize: isMobile ? '18px' : '24px', textAlign: isMobile ? 'center' : 'left'}}>Veja como <span style={{fontWeight: 'bold'}}>é fácil comprar</span> as peças compatíveis que você precisa:</h2>
                                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '16px'}}>
                                                                <img style={{maxHeight: isMobile ? '150px' : '240px'}} src={contentData.step1img} alt={`${configData.defaultStore} passo 1`} loading='lazy'/>
                                                                <img style={{maxHeight: isMobile ? '150px' : '240px'}} src={contentData.step2img} alt={`${configData.defaultStore} passo 2`} loading='lazy'/>
                                                                <img style={{maxHeight: isMobile ? '150px' : '240px'}} src={contentData.step3img} alt={`${configData.defaultStore} passo 3`} loading='lazy'/>
                                                            </div>
                                                            <Button
                                                                className='giantButton'
                                                                iconStart={<span><AddShoppingCart style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                label='Comprar agora'
                                                                onClick={openVehicleSelect}
                                                            />
                                                        </div>
                                                    </SwiperSlide>

                                                    <SwiperSlide>
                                                        <div className='defaultBannerBG' style={{ backgroundImage: `url(${contentData.banner03})`, backgroundColor: 'var(--mainColor)', backgroundPosition: !isMobile ? 'center' : 'left'}}>
                                                            <div className='rowToColumn' style={{columnGap: '20px', rowGap: '10px'}}>
                                                                <div className='row' style={{width: !isMobile ? '35vw' : '100vw'}}>
                                                                    <img style={{maxHeight: isMobile ? '220px' : '360px'}} src={contentData.imgSpecial2} alt={`${configData.defaultStore} logo`} loading='lazy'/>
                                                                </div>
                                                                <div className='column' style={{width: !isMobile ? '45vw' : '90vw',color: 'var(--mainColor) !important', gap: '10px', alignItems: 'start', marginInline: isMobile ? '20px' : '0'}}>
                                                                    <h2 style={{margin: '0', fontSize: isMobile ? '20px' : '24px', textAlign: 'left'}}>Compre peças com <span style={{fontWeight: 'bold'}}>facilidade e tranquilidade</span>!</h2>
                                                                    <span style={{textAlign: 'left', fontSize: isMobile ? '14px' : '18px'}}>
                                                                        Pagamento seguro via <span style={{fontWeight: 'bold'}}>PIX</span> ou em até <span style={{fontWeight: 'bold'}}>{configData.maxParcels}x</span> no cartão de crédito.<br/>Selecione o modelo do seu carro, a peça desejada e receba na sua casa.
                                                                    </span>
                                                                    <div className='row center' style={{gap: '10px'}}>
                                                                        <Button
                                                                            className='buttonColorSecond smallButton'
                                                                            label='Criar conta'
                                                                            onClick={() => navigate('/minha-conta')}
                                                                        />
                                                                        <Button
                                                                            className='buttonColorMain smallButton'
                                                                            label='Ver peças'
                                                                            onClick={openVehicleSelect}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </>
                                            }
    
                                            {configData.defaultStore !== 'Carlider' &&
                                                <SwiperSlide>
                                                    <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner01})`, backgroundColor: 'var(--mainColor)'}}>
                                                        <div className='rowToColumn center' style={{gap: '20px'}}>
                                                            <div className='column center'>
                                                                <img className='BannerLogoLarge' src={contentData.bannerImg01} alt={`${configData.defaultStore} logo`} loading='lazy'/>
                                                            </div>
                                                            <div className='column center' style={{gap: '10px'}}>
                                                                <h2 className='bannerHighlightText' dangerouslySetInnerHTML={{ __html: contentData.bannerHighlight }} />
        
                                                                {/* {!selectedVersion &&
                                                                    <div className='titleContainer not500' style={{flexDirection: 'column'}}>
                                                                        <h1><mark>1.</mark> Selecione seu veículo</h1>
                                                                        <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                                                        <h1><mark>3.</mark> Compre e receba</h1>
                                                                    </div>
                                                                } */}
        
                                                                {!selectedVersion &&
                                                                    <div className='only500'>
                                                                        <h1>Selecione seu veículo:</h1>
                                                                    </div>
                                                                }
                                                                
                                                                {/* {selectedVersion &&
                                                                    <h1>Veículo selecionado:</h1>
                                                                } */}
                                                                
                                                                {!selectedVersion &&
                                                                    <div className='startHereContainer'>
                                                                        <div className='startHere' onClick={openVehicleSelect}>
                                                                            <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                                            <span>Comece aqui</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                
                                                                {!selectedVersion
                                                                    ?
                                                                        <div className='column center' style={{gap: '10px'}}>
                                                                            <div className='bannerRowBrands'>
                                                                                {configData.brands.map((item) => {
                                                                                    const brandName = item.charAt(0).toUpperCase() + item.slice(1);
                                                                                    const imageSrc = BrandsList[brandName];
                                                                                    return imageSrc ? (
                                                                                    <div
                                                                                        className='headerFilterOption'
                                                                                        key={item}
                                                                                        onClick={() => vehicleSelectWithBrand(item)}
                                                                                    >
                                                                                        <img className='headerFilterBrandLogo' src={imageSrc} alt={item} />
                                                                                    </div>
                                                                                    ) : (
                                                                                    <div className='headerFilterOption' style={{ color: 'white' }} key={item}>
                                                                                        {item}
                                                                                    </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div className='column center' style={{gap: '10px'}}>
                                                                            <div className='row selectedVehicleHomeBanner' onClick={openVehicleSelectOnly}>
                                                                                <img className='catVehicleImg' src={modelSeriesImg(modelSeries)}/>
                                                                                <span style={{alignItems: 'center', whiteSpace: 'wrap'}} >{selectedModel} {selectedYear} {selectedVersion}</span>
                                                                                <a style={{whiteSpace: 'nowrap'}}><Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                                                                            </div>
                                                                            <Button
                                                                                className='giantButton'
                                                                                iconStart={<span><AddShoppingCart style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                                label='Peças compatíveis'
                                                                                onClick={openVehicleSelect}
                                                                            />
                                                                        </div>
                                                                }
        
                                                            </div>
        
                                                        </div>
        
                                                    </div>
                                                </SwiperSlide>
                                            }
    
                                            {configData.defaultStore === 'Vigorito' &&
                                                <SwiperSlide>
                                                    <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner05})`}}>
                                                        <div className='rowToColumn center' style={{gap: '20px'}}>
                                                            <div className='column center' style={{gap: '10px', paddingInline: '10px'}}>
                                                                <h2 className= 'bannerHighlightTextLarge'><span style={{color: 'white', fontWeight: 'bold'}}>Kits</span> de <span style={{color: 'yellow', fontWeight: 'bold'}}>Revisão e Manutenção</span></h2>
                                                                <span style={{paddingInline: '10px'}}>Kits completos com itens de fábrca para troca de óleo, revisão, correias e outros.</span>
                                                                <Button
                                                                    className='giantButton'
                                                                    iconStart={<span><KeyboardArrowDown style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                    label='Confira!'
                                                                />
                                                            </div>
                                                            <img className='BannerLogoLarge' src={contentData.bannerImg05} alt={`${configData.defaultStore} logo`} loading='lazy'/>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            }
    
                                            {configData.defaultStore !== 'Carlider' &&
                                                <SwiperSlide>
                                                    {/* <div className='defaultBannerBG' style={{backgroundColor: 'var(--secondColor)'}}> */}
                                                    <div className='defaultBannerBG' style={{backgroundImage: `url(${contentData.banner02})`, backgroundColor: 'var(--mainColor)'}}>
        
                                                        {/* <img src={Logo} className='BannerLogo'/> */}
        
                                                        <h2 className='bannerHighlightText' dangerouslySetInnerHTML={{ __html: contentData.bannerHighlight }} />
        
                                                        <div className='stepsBannerRow'>
                                                            <img src={contentData.car123 === '' ? carBanner : contentData.car123} className='bannerStepsImg'/>
                                                            <ChevronRight/>
                                                            <img src={partsBanner} className='bannerStepsImg'/>
                                                            <ChevronRight/>
                                                            <img src={contentData.box123 === '' ? boxGpBanner : contentData.box123} className='bannerStepsImg'/>
                                                        </div>
        
                                                        <div className='titleContainer'>
                                                            <h1><mark>1.</mark> Selecione seu veículo</h1>
                                                            <h1><mark>2.</mark> Encontre a peça desejada</h1>
                                                            <h1><mark>3.</mark> Compre e receba</h1>
                                                        </div>
        
                                                        {/* <h1>Autopeças <mark>novas e origniais</mark> compatíveis com <mark>seu veículo</mark> e enviadas diretamente pelas <mark>concessionárias</mark>.</h1> */}
                                                        
                                                        {!selectedVersion &&
                                                            <div className='startHereContainer'>
                                                                <div className='startHere' onClick={openVehicleSelect}>
                                                                    <KeyboardDoubleArrowUp style={{color: 'var(--secondColor)'}}/>
                                                                    <span>Comece aqui</span>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='row center'style={{gap: '10px'}}>
                                                            <Button
                                                                className='giantButton'
                                                                iconStart={<span><AddCar style={{color: 'var(--giantButtonGradientContent)', position: 'relative', zIndex: 1}}/></span>}
                                                                label={!selectedVersion ? 'Selecionar veículo' : 'Peças compatíveis'}
                                                                onClick={openVehicleSelect}
                                                            />
        
                                                            {/* <Button
                                                                className='buttonStyle colorButtonMain largeButton'
                                                                label='Encontrar peças'
                                                            /> */}
                                                        </div>
        
        
                                                        {/* <div className='stepsBannerRow'>
                                                            <img src={carBanner} className='bannerStepsImg'/>
                                                            <ChevronRight/>
                                                            <img src={partsBanner} className='bannerStepsImg'/>
                                                            <ChevronRight/>
                                                            <img src={boxGpBanner} className='bannerStepsImg'/>
                                                        </div> */}
        
                                                    </div>
                                                </SwiperSlide>
                                            }
                                        </>
                                    }
            
                                </>
                            }
                        </>
                }
                
            </Swiper>
        </div>
    );
}

export default BannerSlider;