import React, { useState, useEffect } from 'react';
import '../App.css';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { GET_PARTS_LIST_FROM_WH, setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion, eraseSelectedVehicle } from '../reducers/vehicle';
import ProductTile from '../Common/Tiles/ProductTile';
import { Close, FilterAltOutlined, FilterList, PriceChangeOutlined, Sort } from '@mui/icons-material';
import Button from '../Common/Button';
import Product from '../Common/Classes/Product';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackSharp } from '@mui/icons-material';
import { AddCar } from '../Utilities/Icons';
import { contentData, configData } from '../Whitelabel/websitesData';
import UHD_banner_pecas_01 from '../images/UmuaramaHD/UHD_banner_pecas_03.webp';
import UHD_banner_boutique_01 from '../images/UmuaramaHD/UHD_banner_pecas_03.webp';
import UHD_banner_acessorios_01 from '../images/UmuaramaHD/UHD_banner_pecas_03.webp';
import KTM_banner_01 from '../images/UmuaramaMotos/U_ktm_bg_04.1.webp';
import Triumph_banner_01 from '../images/UmuaramaMotos/U_triumph_bg_03.1.webp';

import { Home, KeyboardArrowUp } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { getFunctions, httpsCallable } from "firebase/functions";
import { BrandsList } from '../Utilities/BrandsList';
import { Search } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import websitesIndexData from '../websitesIndexData';
import { buildHelmetData } from '../Utilities/HelmetTool';

function Products() {
    const { category } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { products, image } = location.state || {};
    const [allProducts, setAllProducts] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [displayedProducts, setDisplayedProducts] = useState([]); // For filtered products
    const [sortOption, setSortOption] = useState('default');
    const vehicleState = useSelector(state => state.vehicle);
    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);
    const [priceRangeStart, setPriceRangeStart] = useState('');
    const [priceRangeEnd, setPriceRangeEnd] = useState('');
    const [filteredSizes, setFilteredSizes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [ expandedFilters, setExpandedFilters ] = useState(false);

    const [hasClothing, setHasClothing] = useState(false); //!!!dynamically update this if category has clothing!!!

    const topMargin = (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') ? '440px' : '20px';
    const categoryImages = {
        Boutique: UHD_banner_boutique_01,
        Acessórios: UHD_banner_acessorios_01, // Add other images as needed
        Peças: UHD_banner_pecas_01, // Add other images as needed
        KTM: KTM_banner_01,
        Triumph: Triumph_banner_01,
        // Add more categories and their corresponding images here
    };

    // Use the category to get the corresponding image
    const productPageBg = (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') ? categoryImages[category] || '' : '';

    // Helper to format prices in Brazilian format
    const formatCurrency = (value) => {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return formatter.format(value);
    };

    // Helper to parse formatted prices back to number
    const parseCurrency = (formattedValue) => {
        return parseFloat(formattedValue.replace(/\./g, '').replace(',', '.').replace('R$', '').trim()) || 0;
    };

    const HDcategories = {
        "Boutique": "Boutique",
        "Acessórios": "Acessorios",
        "Peças": "Peças",
        "KTM": "KTM",
        "Triumph": "Triumph",
    };

    useEffect(() => {
        if (!selectedVersion) {
            dispatch(setIsSelectingVehicle(true));
        }
    }, [selectedVersion])

    const vehicleSelectWithBrand = (brand) => {
        dispatch(setSelectedVersion(''));
        dispatch(setSelectedModel(''));
        dispatch(setSelectedYear(''));
        dispatch(setSelectedBrand(brand));
        dispatch(setIsSelectingVehicle(true));
    }

    const openVehicleSelectOnly = () => {
        dispatch(setIsSelectingVehicle(true));
    }

    const loadProducts = async () => {
        if (products) {
            const mappedProducts = products.map(part => { 
                // Filter the sellers based on configData.storeSellersWarehouses
                const filteredSellers = part.sellers.filter(seller =>
                    configData.storeSellersWarehouses.includes(seller.name)
                );
    
                return (
                    new Product(
                        part.active,
                        part.description,
                        part.ds_number,
                        part.id_supplier,
                        part.images,
                        filteredSellers, // Use the filtered sellers
                        { height: part.height, width: part.width, weight: part.weight, depth: part.depth },
                        part.listofPropertiesbyCar,
                    )
                );
            });
    
            dispatch({
                type: GET_PARTS_LIST_FROM_WH,
                payload: mappedProducts,
            });
    
            setAllProducts(mappedProducts);
            setSortedProducts(mappedProducts); // Initialize sorted products with the mapped products
            setDisplayedProducts(mappedProducts); // Initially display all
        } else if (!products && (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos')) {
            async function getHDproducts() {
                const functions = getFunctions();
                const callableGetPartsListFromBrand = httpsCallable(functions, 'getPartsListFromBrand');
        
                // Fetch parts for both brands
                const [harleyParts, ktmParts, triumphParts] = await Promise.all([
                    callableGetPartsListFromBrand({ 'brand': "GENUINAHARLEYDAVIDSON" }),
                    callableGetPartsListFromBrand({ 'brand': "GENUINAKTM" }),
                    callableGetPartsListFromBrand({ 'brand': "GENUINATRIUMPH" })
                ]).then(([harleyResult, ktmResult, triumphResult]) => {
                    return [harleyResult.data, ktmResult.data, triumphResult.data];
                }).catch((error) => {
                    console.error(error);
                    return [[], []]; // Return empty arrays on error
                });
        
                const listofArticles = [...harleyParts, ...ktmParts, ...triumphParts].map(document => {
                    if (document != null) {
                        const listOfSellers = document["sellersList"];
                        const growableList = [];
                        const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";
        
                        listOfSellers.forEach((element) => {
                            if (configData.storeSellersWarehouses.includes(element)) {
                                growableList.push({
                                    id: document["id"].toString(),
                                    active: document[element]["active"],
                                    name: document[element]["name"],
                                    price: document[element]["price"],
                                    stock: document[element]["stock"],
                                    price_ecommerce: document[element].hasOwnProperty(storeprice)
                                        ? document[element][storeprice]
                                        : document[element]["price"],
                                    distance: 1
                                });
                            }
                        });
        
                        return {
                            id: document['id'],
                            active: document['active'],
                            description: document['desc'],
                            ds_number: document['ds_number'],
                            ds_number_gp: document['ds_number_gp'],
                            id_prod: document['id_prod'],
                            id_supplier: document['id_supplier'],
                            images: document['imhash'],
                            listofPropertiesbyCar: document.hasOwnProperty('prop_car') ? document['prop_car'] : null,
                            priority: document['priority'],
                            ds_numbers: document['ds_numbers'],
                            weight: document.hasOwnProperty('pesoG') ? document['pesoG'] : -1,
                            height: document.hasOwnProperty('altCM') ? document['altCM'] : -1,
                            depth: document.hasOwnProperty('profCM') ? document['profCM'] : -1,
                            width: document.hasOwnProperty('largCM') ? document['largCM'] : -1,
                            sellers: growableList,
                        };
                    }
                    return null; // Return null for invalid documents
                }).filter(Boolean); // Filter out null values
        
                const mappedProducts = listofArticles.map(part => {
                    const filteredSellers = part.sellers.filter(seller =>
                        configData.storeSellersWarehouses.includes(seller.name)
                    );
        
                    return new Product(
                        part.active,
                        part.description,
                        part.ds_number,
                        part.id_supplier,
                        part.images,
                        filteredSellers,
                        { height: part.height, width: part.width, weight: part.weight, depth: part.depth },
                        part.listofPropertiesbyCar,
                    );
                });
        
                const callableGetHDCategoriesItems = httpsCallable(functions, 'getHDCategoriesItems');
                const filteredProducts = await callableGetHDCategoriesItems({ 'category': HDcategories[category] }).then((result) => {
                    let filtered;
                    if (category === "KTM") {
                        filtered = mappedProducts.filter(item => item.supplier === "GENUINAKTM" && !result['data'].includes(item.ds_number));
                    } else if (category === "Triumph") {
                        filtered = mappedProducts.filter(item => item.supplier === "GENUINATRIUMPH" && !result['data'].includes(item.ds_number));
                    } else if (category !== "Peças") {
                        filtered = mappedProducts.filter(item => result['data'].includes(item.ds_number));
                    } else {
                        filtered = mappedProducts.filter(item => !result['data'].includes(item.ds_number));
                    }
        
                    // Sort products - prioritize stock first, then images
                    filtered.sort((a, b) => {
                        const aHasStock = a.getSellers().some(seller => seller.stock > 0);
                        const bHasStock = b.getSellers().some(seller => seller.stock > 0);
                        const aHasImages = a.images.length > 0;
                        const bHasImages = b.images.length > 0;
                        
                        // Different stock status
                        if (aHasStock !== bHasStock) {
                            return aHasStock ? -1 : 1;
                        }
                        
                        // Same stock status, sort by images
                        if (aHasImages !== bHasImages) {
                            return aHasImages ? -1 : 1;
                        }
                        
                        return 0; // maintain relative order if both have same status
                    });
        
                    return filtered;
                });
        
                dispatch({
                    type: GET_PARTS_LIST_FROM_WH,
                    payload: filteredProducts,
                });
        
                setAllProducts(filteredProducts);
                setSortedProducts(filteredProducts);
                setDisplayedProducts(filteredProducts);
            }
        
            getHDproducts();
        } else if (!products && (configData.defaultStore === 'NissulYamaha' || configData.defaultStore === 'BelcarYamaha')) {
            async function getYamahaProducts() {
                const functions = getFunctions();
                const callableGetPartsListFromBrand = httpsCallable(functions, 'getPartsListFromBrand');
        
                // Fetch only Yamaha parts
                const yamahaResult = await callableGetPartsListFromBrand({ 'brand': "GENUINAYAMAHA" })
                    .then((result) => {
                        return result.data;
                    })
                    .catch((error) => {
                        console.error(error);
                        return []; // Return empty array on error
                    });
        
                const listofArticles = yamahaResult.map(document => {
                    if (document != null) {
                        const listOfSellers = document["sellersList"];
                        const growableList = [];
                        const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";
        
                        listOfSellers.forEach((element) => {
                            if (configData.storeSellersWarehouses.includes(element)) {
                                growableList.push({
                                    id: document["id"].toString(),
                                    active: document[element]["active"],
                                    name: document[element]["name"],
                                    price: document[element]["price"],
                                    stock: document[element]["stock"],
                                    price_ecommerce: document[element].hasOwnProperty(storeprice)
                                        ? document[element][storeprice]
                                        : document[element]["price"],
                                    distance: 1
                                });
                            }
                        });
        
                        return {
                            id: document['id'],
                            active: document['active'],
                            description: document['desc'],
                            ds_number: document['ds_number'],
                            ds_number_gp: document['ds_number_gp'],
                            id_prod: document['id_prod'],
                            id_supplier: document['id_supplier'],
                            images: document['imhash'],
                            listofPropertiesbyCar: document.hasOwnProperty('prop_car') ? document['prop_car'] : null,
                            priority: document['priority'],
                            ds_numbers: document['ds_numbers'],
                            weight: document.hasOwnProperty('pesoG') ? document['pesoG'] : -1,
                            height: document.hasOwnProperty('altCM') ? document['altCM'] : -1,
                            depth: document.hasOwnProperty('profCM') ? document['profCM'] : -1,
                            width: document.hasOwnProperty('largCM') ? document['largCM'] : -1,
                            sellers: growableList,
                        };
                    }
                    return null; // Return null for invalid documents
                }).filter(Boolean); // Filter out null values
        
                const mappedProducts = listofArticles.map(part => {
                    const filteredSellers = part.sellers.filter(seller =>
                        configData.storeSellersWarehouses.includes(seller.name)
                    );
        
                    return new Product(
                        part.active,
                        part.description,
                        part.ds_number,
                        part.id_supplier,
                        part.images,
                        filteredSellers,
                        { height: part.height, width: part.width, weight: part.weight, depth: part.depth },
                        part.listofPropertiesbyCar,
                    );
                });
        
                // Sort products - prioritize stock first, then images
                const sortedProducts = mappedProducts.sort((a, b) => {
                    const aHasStock = a.getSellers().some(seller => seller.stock > 0);
                    const bHasStock = b.getSellers().some(seller => seller.stock > 0);
                    const aHasImages = a.images.length > 0;
                    const bHasImages = b.images.length > 0;
                    
                    // Different stock status
                    if (aHasStock !== bHasStock) {
                        return aHasStock ? -1 : 1;
                    }
                    
                    // Same stock status, sort by images
                    if (aHasImages !== bHasImages) {
                        return aHasImages ? -1 : 1;
                    }
                    
                    return 0; // maintain relative order if both have same status
                });
        
                dispatch({
                    type: GET_PARTS_LIST_FROM_WH,
                    payload: sortedProducts,
                });
        
                setAllProducts(sortedProducts);
                setSortedProducts(sortedProducts);
                setDisplayedProducts(sortedProducts);
            }
        
            getYamahaProducts();
        }
    };


    useEffect(() => {
        sortProducts(sortOption);
    }, [sortOption, allProducts]);

       // Initial load of products
    useEffect(() => {
        setIsLoading(true);
        loadProducts();
    }, [products, dispatch]);

    // New useEffect to listen for category changes
    useEffect(() => {
        setIsLoading(true);
        // Call the loadProducts function whenever the category changes
        loadProducts();
    }, [category]); // Add category as a dependency

    useEffect(() => {
        filterProductsByPrice();
    }, [priceRangeStart, priceRangeEnd]);

    const sortProducts = (option) => {
        let sorted = [...allProducts];
    
        // Helper function to get the price of a product
        const getPrice = (product, priceType) => {
            const sellers = product.getSellers();
            if (sellers.length > 0) {
                return sellers[0][priceType] || 0; // Return 0 if price is missing
            }
            return 0; // If no sellers, return 0
        };
    
        // Helper function to calculate the absolute discount
        const getDiscount = (product) => {
            const originalPrice = getPrice(product, "price"); // Use "price" for original price
            const ecommercePrice = getPrice(product, "price_ecommerce");
            // Return 0 discount if either price is 0 or missing
            return (originalPrice <= 0 || ecommercePrice <= 0) ? 0 : Math.abs(originalPrice - ecommercePrice);
        };
    
        if (option === 'lowestPrice') {
            sorted.sort((a, b) => {
                const priceA = getPrice(a, "price_ecommerce");
                const priceB = getPrice(b, "price_ecommerce");
    
                // Move products without sellers to the end
                if (priceA === 0 && priceB === 0) return 0; // Both products have no sellers
                if (priceA === 0) return 1; // a has no sellers, put it after b
                if (priceB === 0) return -1; // b has no sellers, put it after a
    
                return priceA - priceB; // Sort by lowest price
            });
        } else if (option === 'highestPrice') {
            sorted.sort((a, b) => {
                const priceA = getPrice(a, "price_ecommerce");
                const priceB = getPrice(b, "price_ecommerce");
                return priceB - priceA;
            });
        } else if (option === 'alphabetical') {
            sorted.sort((a, b) => a.getDescription().localeCompare(b.getDescription()));
        } else if (option === 'highestDiscount') {
            sorted.sort((a, b) => getDiscount(b) - getDiscount(a)); // Sort by absolute discount, highest to lowest
        }
    
        setSortedProducts(sorted);
        setDisplayedProducts(sorted); // Update the displayed products whenever sorting changes
    };

    const filterProductsByPrice = () => {
        const startPrice = parseCurrency(priceRangeStart) || 0;
        const endPrice = parseCurrency(priceRangeEnd) || Infinity;

        const filteredProducts = sortedProducts.filter(product => {
            const price = product.getSellers().length > 0 ? product.getSellers()[0].price_ecommerce : 0;
            return price >= startPrice && price <= endPrice;
        });

        setDisplayedProducts(filteredProducts);
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const openVehicleSelect = () => {
        dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    };

    const handlePriceRangeChangeStart = (event) => {
        const value = event.target.value.replace(/\D/g, ''); // Only allow numbers
        const numericValue = parseInt(value, 10); // Convert to integer
        const formattedValue = numericValue > 0 ? formatCurrency(numericValue / 100) : '';
        setPriceRangeStart(formattedValue);
    };
    
    const handlePriceRangeChangeEnd = (event) => {
        const value = event.target.value.replace(/\D/g, ''); // Only allow numbers
        const numericValue = parseInt(value, 10); // Convert to integer
        const formattedValue = numericValue > 0 ? formatCurrency(numericValue / 100) : '';
        setPriceRangeEnd(formattedValue);
    };

    const sizeFilterOptions = {
        'XS': 'PP',
        'S': 'P',
        'M': 'M',
        'L': 'G',
        'XL': 'GG',
        '2XL': '3G',
    }

    const handleSizeSelect = (size) => {
        setFilteredSizes(prevSizes =>
            prevSizes.includes(size) ? prevSizes.filter(s => s !== size) : [...prevSizes, size]
        );
    };

    const handleClearFilters = () => {
        setFilteredSizes([]);
        setExpandedFilters(false);
        setPriceRangeStart('');
        setPriceRangeEnd('');
    };

    useEffect(() => {
        if (displayedProducts.length > 0) {
            setIsLoading(false);
        }
    }, [displayedProducts]);

    useEffect(() => {
        if ((!configData.noVehicle) && !selectedVersion) {
            openVehicleSelect();
        }
    }, [selectedVersion]);

    const keepVehicleSelection = () => {
        if ((configData.noVehicle)) {
            dispatch(setIsSelectingVehicle(false));
            dispatch(setSelectedBrand('uhd'));
            dispatch(setSelectedYear('2020'));
            dispatch(setSelectedModel('softail'));
            dispatch(setSelectedVersion('abc'));
        }
    }

    useEffect(() => {
        keepVehicleSelection();
    }, [configData.noVehicle]);

    // Add helmetData using the unified utility
    const helmetData = buildHelmetData(configData, websitesIndexData, {
        pageName: category,
        allowIndex: true, // Product category pages should be indexed for SEO
        additionalKeywords: `${category?.toLowerCase()}, peças originais, ${selectedBrand || ''} ${selectedModel || ''}, ${selectedYear || ''}, autopeças, peças genuínas, ${displayedProducts.slice(0, 5).map(p => p.getDescription().split(' ')[0]).join(', ')}`
    });

    return (
        <div className='main fadeIn'>
            {helmetData && (
                <Helmet>
                    <title>{helmetData.title}</title>
                    <meta name="description" content={helmetData.description} />
                    
                    <meta name="robots" content={helmetData.robots} />
                    <meta name="googlebot" content={helmetData.robots} />
                    
                    <meta property="og:type" content={helmetData.social.type} />
                    <meta property="og:url" content={helmetData.social.url} />
                    <meta property="og:title" content={helmetData.social.title} />
                    <meta property="og:description" content={helmetData.social.description} />
                    <meta property="og:image" content={helmetData.social.image} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={helmetData.social.url} />
                    <meta property="twitter:title" content={helmetData.social.title} />
                    <meta property="twitter:description" content={helmetData.social.description} />
                    <meta property="twitter:image" content={helmetData.social.image} />
                    
                    <meta name="keywords" content={helmetData.keywords} />
                    <link rel="canonical" href={helmetData.canonical} />
                </Helmet>
            )}
            {(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') &&
                <div className='productsPageBg' style={{ backgroundImage: `url(${productPageBg})` }}>
                        <div className='umuaramaHDnav'>
                            <Home style={{ color: 'var(--secondColor)', fontSize: '18px' }} />
                            <Link to='/'>Página Inicial</Link>
                            <span>›</span>
                            <span>{category}</span>
                        </div>
                </div>
            }
            <div className={`pageContent ${(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') ? 'UhdTopMargin' : ''}`}>
                <div className='pageHeader'>
                    {(configData.defaultStore !== 'NissulYamaha' && configData.defaultStore !== 'BelcarYamaha') &&
                        <div className='row' style={{ alignItems: 'center', gap: '6px', whiteSpace: 'nowrap' }}>
                            <a className='grayedText' onClick={handleBackClick}><ArrowBackSharp style={{ fontSize: '18px', marginRight: '4px' }} /></a>
                            {image && <img src={image} alt={`Autopeças em ${category}`} style={{ width: '48px' }} />}
                            <h1 style={{ whiteSpace: 'wrap' }}>{category}</h1>
                        </div>
                    }
                    {(vehicleState.selectedVersion || (configData.noVehicle)) &&
                        <div className='column' style={{width: '100%', gap: '10px'}}>
                            <div className='row' style={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div className='row' style={{ alignItems: 'center', gap: '6px' }}>
                                    <Sort />
                                    <span style={{ whiteSpace: 'nowrap' }}>Ordenar: </span>
                                    <select style={{ maxWidth: '200px' }} onChange={handleSortChange}>
                                        <option value='default'>Relevância</option>
                                        <option value='lowestPrice'>Menor preço</option>
                                        <option value='highestPrice'>Maior preço</option>
                                        <option value='highestDiscount'>Maior desconto</option>
                                        <option value='alphabetical'>Ordem Alfabética</option>
                                    </select>
                                </div>

                                {/* {expandedFilters
                                    ?
                                        <Button
                                            className='buttonColorSecond smallButton'
                                            iconStart={<KeyboardArrowUp/>}
                                            onClick={() => setExpandedFilters(false)}
                                        />
                                    :
                                        <div style={{position: 'relative'}}>
                                            <Button
                                                className='buttonColorSecond smallButton'
                                                iconStart={<FilterList/>}
                                                onClick={() => setExpandedFilters(true)}
                                            />
                                            {(filteredSizes.length > 0 || priceRangeStart !== '' || priceRangeEnd !== '') &&
                                                <div className='filterIndicator'/>
                                            }
                                        </div>
                                } */}

                            </div>
                            
                            {/* {expandedFilters &&
                                <div className='column fadeIn' style={{gap: '6px', margin: '6px 0 10px 0'}}>
                                    <div className='row' style={{ alignItems: 'center', gap: '6px' }}>
                                        <span style={{ whiteSpace: 'nowrap' }}>Faixa de preço: </span>
                                        <span className='grayedText'>de</span>
                                        <input
                                            style={{ maxWidth: '90px' }}
                                            value={priceRangeStart}
                                            onChange={handlePriceRangeChangeStart}
                                            placeholder="R$ 0,00"
                                        />
                                        <span className='grayedText'>até</span>
                                        <input
                                            style={{ maxWidth: '90px' }}
                                            value={priceRangeEnd}
                                            onChange={handlePriceRangeChangeEnd}
                                            placeholder="R$ 0,00"
                                        />
                                    </div>

                                    {hasClothing &&
                                        <div className='row' style={{ alignItems: 'center', gap: '6px' }}>
                                            <span style={{ whiteSpace: 'nowrap' }}>Tamanhos: </span>
                                            <div className='row' style={{gap: '4px'}}>
                                                {Object.entries(sizeFilterOptions).map(([key, value]) => (
                                                    <div
                                                        key={key}
                                                        className={`defaultBorderedItem sizePicker ${filteredSizes.includes(value) ? 'selectedSizePicker' : ''}`}
                                                        onClick={() => handleSizeSelect(value)}
                                                    >
                                                        <span>{value}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    {(filteredSizes.length > 0 || priceRangeStart !== '' || priceRangeEnd !== '') &&
                                        <div className='row' style={{alignItems: 'center', gap: '4px', justifyContent: 'end'}}>
                                            <Close style={{fontSize: '16px', color: 'var(--secondColor)'}}/>
                                            <a className='fadeIn' onClick={handleClearFilters}>remover filtros</a>
                                        </div>
                                    }
                                </div>
                            } */}
                            
                        </div>
                    }
                    </div>
                    {vehicleState.selectedVersion || configData.defaultStore === 'UmuaramaHD'
                        ? 
                            (
                                <>
                                    {category !== '...'
                                        ?
                                            <>
                                                {isLoading
                                                    ?
                                                        <div className='column center' style={{minHeight: '200px', maxHeight: '80vh'}}>
                                                            <CircularProgress
                                                                sx={{ color: 'var(--secondColor)' }}
                                                            />
                                                        </div>
                                                    :
                                                        <div className='centerGrid' style={{ marginTop: '10px' }}>
                                                            {displayedProducts.length > 0
                                                                ?
                                                                    <div className='tileGrid fadeIn'>
                                                                        {displayedProducts.map(product => (
                                                                            <ProductTile
                                                                                key={product.getCode()}
                                                                                product={product}
                                                                                vehicle={vehicleState}
                                                                                defaultImage={image}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                :
                                                                    <span className='grayedText'>Nenhum produto encontrado</span>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            :
                                        <div className='center' style={{marginTop: '40px'}}>
                                            <h1>Peças Triumph em breve.</h1>
                                        </div>
                                    }
                                </>
                            )
                        :
                            (
                                <div className='pageContent center column' style={{ gap: '10px' }}>
                                    <h2 style={{ textAlign: 'center' }}>
                                        Selecione um veículo para ver peças compatíveis em <span style={{ fontWeight: '700' }}>{category}</span>.
                                    </h2>
                                    <Button
                                        className='giantButton'
                                        iconStart={<span><AddCar style={{color: 'white', position: 'relative', zIndex: 1}}/></span>}
                                        label='Selecionar'
                                        onClick={openVehicleSelect}
                                    />
                                </div>
                                // <div className='column center' style={{gap: '10px'}}>
                                //     <h2 style={{ textAlign: 'center' }}>
                                //         Selecione um veículo para ver peças compatíveis em <span style={{ fontWeight: '700' }}>{category}</span>.
                                //     </h2>
                                //     <div className='defaultRowBrands'>
                                //         {configData.brands.map((item) => {
                                //             const brandName = item.charAt(0).toUpperCase() + item.slice(1);
                                //             const imageSrc = BrandsList[brandName];
                                //             return imageSrc ? (
                                //             <div
                                //                 className='headerFilterOption'
                                //                 key={item}
                                //                 onClick={() => vehicleSelectWithBrand(item)}
                                //             >
                                //                 <img className='headerFilterBrandLogo' src={imageSrc} alt={item} />
                                //             </div>
                                //             ) : (
                                //             <div className='headerFilterOption' style={{ color: 'white' }} key={item}>
                                //                 {item}
                                //             </div>
                                //             );
                                //         })}
                                //     </div>
                                //     <div className='column center' style={{gap: '6px'}}>
                                //         <span>ou</span>
                                //         <Button
                                //             onClick={openVehicleSelectOnly}
                                //             className="buttonColorThird smallButton"
                                //             label="Encontre pela placa"
                                //             iconStart={<Search/>}
                                //         />
                                //     </div>
                                // </div>
                            )
                    }
                </div>
            </div>
        );
    }
    
    export default Products;
    