import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion} from './reducers/vehicle'; // Import actions from vehicle.js
import { contentData, configData } from './Whitelabel/websitesData';
import Header from './Header/Header';
import Home from './Home/Home';
import Footer from './Common/Footer';
import Checkout from './Checkout/Checkout';
import Login from './Account/Login';
import Signup from './Account/Signup';
import ProductPage from './Product/ProductPage';
import SearchResultsPage from './Navigation/SearchResultsPage';
import ModelSelect from './Navigation/ModelSelect';
import Categories from './Navigation/Categories';
import VehicleSelect from './Common/VehicleSelect';
import ScrollTop from './Utilities/ScrollTop';
import Products from './Navigation/Products';
import MyAccount from './Account/MyAccount';
import ClickOutside from './Utilities/ClickOutside';
import ConfirmationPage from './Checkout/ConfirmationPage';
import Privacy from './Legal/Privacy';
import TermsAndConditions from './Legal/TermsAndConditions';
import Return from './Legal/Return';
import Shipping from './Legal/Shipping';
import Refund from './Legal/Refund';
import FloatingButton from './Common/FloatingButton';
import AboutUs from './Common/AboutUs';
import ResetPwd from './Account/ResetPwd';
import CookieBar from './Common/CookieBar';
import DefaultPopup from './Common/DefaultPopup';
import Button from './Common/Button';
import { UserProvider, useUser } from './Providers/UserContext.js'
import AdminPage from './Utilities/AdminPage';


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    if (configData.defaultStore === 'Carlider') {
      setIsIframe(window.self !== window.top);
    }
  }, []);

  const { isSelectingVehicle } = useSelector(state => state.vehicle);
  const [ leadFormName, setLeadFormName ] = useState('');
  const [ leadFormEmail, setLeadFormEmail ] = useState('');
  
  const { gpuser, isUserLoading } = useUser();

  const [ visibleHomeDialog, setVisibleHomeDialog ] = useState(false);
  const [ homeOfferRejected, setHomeOfferRejected ] = useState(() => sessionStorage.getItem('homeOfferRejected') === 'true');

  const keepVehicleSelection = () => {
    if ((configData.noVehicle)) {
        dispatch(setIsSelectingVehicle(false));
        dispatch(setSelectedBrand('uhd'));
        dispatch(setSelectedYear('2020'));
        dispatch(setSelectedModel('softail'));
        dispatch(setSelectedVersion('abc'));
    }
  }

  useEffect(() => {
      keepVehicleSelection();
  }, [configData.noVehicle]);

  const closeVehicleSelect = () => {
    if (isSelectingVehicle) {
      dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    }
  }

  const closeHomeDialog = () => {
    setVisibleHomeDialog(false);
  }

  const leadFormSubmit = (e) => {
    e.preventDefault();
    //console.log('leadForm: ', leadFormName, leadFormEmail);
    setVisibleHomeDialog(false);
  }

  const rejectHomeOffer = () => {
    setVisibleHomeDialog(false);
    sessionStorage.setItem('homeOfferRejected', 'true');
  }

  useEffect(() => {
    if (!gpuser && !isUserLoading) {
      setVisibleHomeDialog(true);
    }
  }, [gpuser, isUserLoading])

  const checkUserInfo = () => {
    if (gpuser.telefone === '' || (gpuser.cpf === '' && gpuser.cnpj === '') || !gpuser.address || gpuser.address.length === 0) {
      navigate('/minha-conta#meus-dados');
    }
  }

  useEffect(() => {
    if (gpuser) {
      checkUserInfo();
    }
  }, [gpuser])

  return (
    <UserProvider>
      <div className="App" translate="no">
      {/* <div className="App" style={{ marginTop: isIframe ? '67px' : '0' }}> */}
        {/* {!gpuser && !homeOfferRejected && visibleHomeDialog && (configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') &&
          <DefaultPopup
              title="Fique por dentro das melhores ofertas!"
              onClose={closeHomeDialog}
              bgColor='var(--mainColor)'
              contentColor='var(--mainContent)'
              titleColor='var(--secondColor)'
          >
            <span>Cadastre seu email e <span style={{ color: 'var(--secondColor)' }}>ganhe 5%</span> na sua próxima compra</span>
            <form className='column' style={{gap: '10px', marginTop: '10px'}} onSubmit={leadFormSubmit}>
              <input
                placeholder='Digite seu nome'
                value={leadFormName}
                onChange={(e) => setLeadFormName(e.target.value)}
              />
              <input
                placeholder='Digite seu email'
                value={leadFormEmail}
                onChange={(e) => setLeadFormEmail(e.target.value)}
              />
              <Button
                className='buttonColorThird smallButton'
                label='Cadastrar'
                type='submit'
              />
              <div className='row center' style={{marginTop: '10px'}}>
                <a onClick={rejectHomeOffer}>Não mostrar de novo</a>
              </div>
            </form>
          </DefaultPopup>
        } */}

        <CookieBar/>
        <Header />
        {(!configData.noVehicle) &&
          <ClickOutside onOutsideClick={closeVehicleSelect}>
            <VehicleSelect/>
          </ClickOutside>
        }
        <ScrollTop />
        <FloatingButton/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/recuperar-senha" element={<ResetPwd />} />
          <Route path="/minha-conta" element={<MyAccount />} />
          <Route path="/categorias" element={<Categories />} />
          <Route path="/produtos/:category" element={<Products />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirmacao/:result" element={<ConfirmationPage />} />
          <Route path="/busca/:searchquery" element={<SearchResultsPage />} />
          <Route path=":brand/:year/modelos" element={<ModelSelect />} />

          <Route path="/privacidade" element={<Privacy/>} />
          <Route path="/termos-e-condicoes" element={<TermsAndConditions/>} />
          <Route path="/troca-e-devolucao" element={<Return/>} />
          {configData.defaultStore !== 'Peças Kia' &&
            <Route path="/reembolso" element={<Refund/>} />
          }
          {configData.defaultStore === 'Peças Kia' &&
            <Route path="/entrega" element={<Shipping/>} />
          }

          <Route path="/sobre-nos" element={<AboutUs/>} />
          <Route path="/sellers-ativos" element={<AdminPage/>} />

          <Route path="/:id" element={<ProductPage />} />
        </Routes>
        <Footer />
      </div>
    </UserProvider>
  );
}

export default App;