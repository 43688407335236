import React, { useState, useEffect } from 'react';
import '../App.css';
import { CategoriesPH } from '../Utilities/Placeholders';
import { categoriesCars, categoriesBikes, bikeBrands } from '../Utilities/CategoriesLists';
import CategoryTile from '../Common/Tiles/CategoryTile';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadOEbyCarCodeType, setIsSelectingVehicle, LOAD_OE_BY_CARCODE_TYPE, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../reducers/vehicle';
import { subcatPeloNomeCategoria, subcatPeloNomeCategoriaBikes, idsSubcategoriaPeloNome, categoryAxle, CategoriesUHD } from '../Utilities/CategoriesLists';
import { getFunctions, httpsCallable } from "firebase/functions";
import { defaultStore, storeSellersWarehouses } from '../Common/Breakpoints';
import CircularProgress from '@mui/material/CircularProgress';
import { Close, Edit, Logout, Add, Search } from '@mui/icons-material';
import { AddCar } from '../Utilities/Icons';
import Button from '../Common/Button';
import { ModelImgs } from '../Utilities/ModelImgs';
import GenericVehicle from '../images/0.png';
import { contentData, configData } from '../Whitelabel/websitesData';
import { handleWhatsappClick } from '../Utilities/Whatsapp';
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import { Helmet } from 'react-helmet';
import websitesIndexData from '../websitesIndexData';
import { buildHelmetData } from '../Utilities/HelmetTool';

function Categories() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedCat, setSelectedCat] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialDataLoading, setInitialDataLoading] = useState(true); // New state for initial data loading
    const selectedCar = useSelector(state => state.vehicle.selectedVehicle);
    const vehicle = useSelector(state => state.vehicle.selectedVehicle);
    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion, modelSeries } = useSelector(state => state.vehicle);
    const internalID = (configData.noVehicle) ? '68444' : vehicle.InternalID;
    const allOEs = useSelector(state => state.vehicle.carOEs);
    const Categories = (selectedBrand && bikeBrands.includes(selectedBrand.toLowerCase())) ? categoriesBikes : categoriesCars;
    const modelSeriesImg = (modelId) => {
        // console.log('modelId: ', modelId);
        return ModelImgs[modelId] || GenericVehicle;
    };

    const keepVehicleSelection = () => {
        if (configData.noVehicle) {
        // if (!selectedVersion && (configData.noVehicle)) {
            dispatch(setIsSelectingVehicle(false));
            dispatch(setSelectedBrand('uhd'));
            dispatch(setSelectedYear('2020'));
            dispatch(setSelectedModel('softail'));
            dispatch(setSelectedVersion('abc'));
        }
    }
    
    useEffect(() => {
        keepVehicleSelection();
    }, [configData.noVehicle]);

    useEffect(() => {
        if((internalID !== undefined) && (internalID !== "")) {
            dispatch(loadOEbyCarCodeType(internalID)).then(() => {
                setInitialDataLoading(false); // Set to false after initial data is loaded
            });
        } else {
            setInitialDataLoading(false); // Set to false if there's no internalID
        }
    }, [selectedCar]);
    
    useEffect(() => {
        if (!selectedVersion) {
            dispatch(setIsSelectingVehicle(true));
        }
    }, [selectedVersion])

    useEffect(() => {
        if (!internalID && allOEs?.parts && (configData.noVehicle)) {
            localStorage.setItem('carOEs', JSON.stringify([])); // Clear localStorage
            dispatch({
                type: LOAD_OE_BY_CARCODE_TYPE,
                payload: [], // Dispatch empty array to Redux state
            });
        }
    }, [internalID]);

    // useEffect(() => {
    //     console.log('allOEs: ', allOEs);
    // }, [allOEs]);

    useEffect(() => {
        if (selectedCat && products.length !== 0) {
            setLoading(false);
            navigate(`/produtos/${selectedCat.name}`, 
                {
                    state: {
                        products: products,
                        image: selectedCat.image
                    }
                }
            );
        }
    }, [selectedCat, navigate, products]);

    useEffect(() => {
        if (configData.defaultStore === 'NissulYamaha' || configData.defaultStore === 'BelcarYamaha') {
            categoryClick('Peças', configData.mainLogoSquare);
        }
    }, [configData.defaultStore]);

    const categoryClick = async (clickedCat, image) => {
        setLoading(true);
        const categoryIds = getCategoriesIds(clickedCat);
        
        const category = {
            name: clickedCat,
            image: image
        };
    
        // Check if allOEs and allOEs.parts are defined
        if (allOEs?.parts && allOEs.parts.length > 0) {
            try {
                await loadParts(selectedCar, categoryIds, true, "all", allOEs);
                setSelectedCat(category);
            } catch (error) {
                console.error('Error loading parts:', error);
            }
        } else {
            console.error('allOEs or allOEs.parts is not defined or empty');
            setLoading(false); // Stop loading if allOEs is not defined
        }
    };
    
    const getCategoriesIds = (name) => {
        const subCategories = bikeBrands.includes((selectedBrand || '').toLowerCase()) ? subcatPeloNomeCategoriaBikes[name] : subcatPeloNomeCategoria[name];
        const listofIds = [];
        subCategories.forEach((element) => {
            // console.log(`Processing element: ${element}`);
            const ids = idsSubcategoriaPeloNome[element];
            if (ids) {
                listofIds.push(...ids);
            } else {
                console.warn(`No IDs found for element: ${element}`);
            }
        });
        return listofIds;
    };
    
    const loadParts = async (car, ids, starting, subcategory, allOEs) => {
        const partsList = [];
    
        ids.forEach((categoryId) => {
            allOEs.parts.forEach((oe) => {
                if (oe && oe.id_prod && oe.id_prod.toString() === categoryId.toString()) {
                    if (!partsList.includes(oe.oe_gpm)) partsList.push([oe.oe_gpm]);
                }
            });
        });
    
        const distinctIds = partsList;
    
        const axle = categoryAxle[subcategory?.toString() || 'default']; // Safeguard for subcategory
    
        const requestPayload = {
            data: distinctIds,
            cross: false,
        };
    
        if (configData.defaultStore === 'UmuaramaHD') {
            requestPayload.brand = 'GENUINAHARLEYDAVIDSON';
        } else if (configData.defaultStore === 'UmuaramaMotos') {
            // Create request payload for GENUINAHARLEYDAVIDSON
            const harleyPayload = {
                ...requestPayload,
                brand: 'GENUINAHARLEYDAVIDSON',
            };
    
            // Create request payload for GENUINAKTM
            const ktmPayload = {
                ...requestPayload,
                brand: 'GENUINAKTM',
            };
    
            // Send requests for both brands
            const [WHpartsHarley, WHpartsKTM] = await Promise.all([
                getPartsListFromWH(harleyPayload),
                getPartsListFromWH(ktmPayload),
            ]);
    
            // Combine results from both requests
            const combinedParts = [...WHpartsHarley, ...WHpartsKTM];
            setProducts(combinedParts);
            return; // Exit the function after handling both brands
        } else if (configData.defaultStore === 'NissulYamaha' || configData.defaultStore === 'BelcarYamaha') {
            requestPayload.brand = 'GENUINAYAMAHA';
        } else {
            requestPayload.axle = axle;
            requestPayload.carID = car.InternalID.toString();
        }
    
        const WHparts = await getPartsListFromWH(requestPayload);
        setProducts(WHparts);
    }

    const getPartsListFromWH = async (info) => {
        const functions = getFunctions();
        const callableGetPartsListFromWH = httpsCallable(functions, `${(configData.noVehicle) ? 'getPartsListFromBrand' : 'getPartsListFromWH'}`);
    
        const parts = await callableGetPartsListFromWH(info).then((result) => {
            let i = 0;
            const listofArticles = [];
            result.data.forEach((document) => {
                i = i + 1;
                if (document != null) {
                    const listOfSellers = document["sellersList"];
                    const growableList = [];
                    const storeprice = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";

                    listOfSellers.forEach((element) => {
                        if (configData.storeSellersWarehouses.includes(element)) {
                            growableList.push({
                                id: document["id"].toString(),
                                active: document[element]["active"],
                                name: document[element]["name"],
                                price: document[element]["price"],
                                stock: document[element]["stock"],
                                price_ecommerce: document[element].hasOwnProperty(storeprice)
                                ? document[element][storeprice]
                                : document[element]["price"],
                                distance: 1
                            });
                        }
                    });

                    growableList.sort((a, b) => {
                        var mina = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                        var minb = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;

                        if (mina < minb) return -1;

                        if (minb < mina) return 1;
                        return 0;
                    });

                    listofArticles.push({
                        id: document['id'],
                        active: document['active'],
                        description: document['desc'],
                        ds_number: document['ds_number'],
                        ds_number_gp: document['ds_number_gp'],
                        id_prod: document['id_prod'],
                        id_supplier: document['id_supplier'],
                        images: document['imhash'],
                        listofPropertiesbyCar: document.hasOwnProperty('prop_car')
                            ? document['prop_car']
                            : null,
                        priority: document['priority'],
                        ds_numbers: document['ds_numbers'],
                        weight: document.hasOwnProperty('pesoG')
                            ? document['pesoR']
                            : -1,
                        weightCubic: document.hasOwnProperty('pesoG')
                            ? document['pesoG']
                            : -1,
                        height: document.hasOwnProperty('altCM')
                            ? document['altCM']
                            : -1,
                        depth: document.hasOwnProperty('profCM')
                            ? document['profCM']
                            : -1,
                        width: document.hasOwnProperty('largCM')
                            ? document['largCM']
                            : -1,
                        sellers: growableList,
                    });
                }
            });

            if (listofArticles.length === 0) {
                listofArticles.push({
                    id: "0",
                    active: false,
                    description: "Sem cadastro de peça",
                    ds_number: "Sem cadastro de peça",
                    ds_number_gp: "Sem cadastro de peça",
                    id_prod: "Sem cadastro de peça",
                    id_supplier: "0",
                    images: [],
                    sellers: [],
                    listofPropertiesbyCar: {},
                    priority: 0,
                    weight: -1,
                    height: -1,
                    depth: -1,
                    width: -1,
                    ds_numbers: [],
                });
            }

            listofArticles.sort((a, b) => {
                const totalA = a.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                const totalB = b.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
                return totalB - totalA;
            });

            return listofArticles;
        }).catch((error) => {
          console.error(error);
        });

        return parts;
    };

    const openVehicleSelect = () => {
        dispatch(setIsSelectingVehicle(!isSelectingVehicle));
    };

    // Add helmetData using the unified utility
    const helmetData = buildHelmetData(configData, websitesIndexData, {
        pageName: 'Categorias',
        allowIndex: true, // Categories page should be indexed for SEO
        additionalKeywords: `peças originais, autopeças, categorias, filtros, freios, motor, suspensão, ${selectedBrand || ''} ${selectedModel || ''} ${selectedYear || ''}`
    });

    return (
        <div className='main fadeIn'>
            {helmetData && (
                <Helmet>
                    <title>{helmetData.title}</title>
                    <meta name="description" content={helmetData.description} />
                    
                    <meta name="robots" content={helmetData.robots} />
                    <meta name="googlebot" content={helmetData.robots} />
                    
                    <meta property="og:type" content={helmetData.social.type} />
                    <meta property="og:url" content={helmetData.social.url} />
                    <meta property="og:title" content={helmetData.social.title} />
                    <meta property="og:description" content={helmetData.social.description} />
                    <meta property="og:image" content={helmetData.social.image} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={helmetData.social.url} />
                    <meta property="twitter:title" content={helmetData.social.title} />
                    <meta property="twitter:description" content={helmetData.social.description} />
                    <meta property="twitter:image" content={helmetData.social.image} />
                    
                    <meta name="keywords" content={helmetData.keywords} />
                    <link rel="canonical" href={helmetData.canonical} />
                </Helmet>
            )}
            <div className='pageContent'>
                {selectedVersion || configData.defaultStore ==='UmuaramaHD'
                    ?
                        <>
                            {initialDataLoading || loading
                                ? 
                                    <div className='center'>
                                        <CircularProgress
                                            style={{padding: '20px'}}
                                            sx={{
                                                color: 'var(--mainColor)'
                                            }}
                                        />
                                    </div>
                                :
                                    <>
                                        {allOEs?.parts && allOEs.parts.length > 0
                                            ?
                                                <>
                                                    {(!configData.noVehicle) &&
                                                        <div className='pageHeader'>
                                                            {/* <h1>Peças para:</h1> */}
                                                            <div className='row defaultBorderedItem clickArea' style={{gap: '10px', alignItems: 'center', width: 'fit-content', backgroundColor: 'white'}} onClick={openVehicleSelect}>
                                                                <img className='catVehicleImg' src={modelSeriesImg(modelSeries)}/>
                                                                <a style={{color: 'var(--mainColor)', alignItems: 'center', whiteSpace: 'wrap'}} >{selectedModel} {selectedYear} {selectedVersion}</a>
                                                                <a style={{whiteSpace: 'nowrap'}}><Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='centerGrid' style={{marginTop: '10px'}}>
                                                        {(configData.defaultStore !== 'UmuaramaHD' && configData.defaultStore !== 'UmuaramaMotos')
                                                            ?
                                                                <div className='tileGrid'>
                                                                    {Object.entries(Categories).map(([category, image]) => (
                                                                        <CategoryTile
                                                                            key={category}
                                                                            name={category}
                                                                            image={image}
                                                                            onClick={() => !loading && categoryClick(category, image)}
                                                                            // noMargin='0'
                                                                            // imgSize='small'
                                                                        />
                                                                    ))}
                                                                </div>
                                                            :
                                                                <div className='tileGrid'>
                                                                    {Object.entries(CategoriesUHD).map(([category, image]) => (
                                                                        <CategoryTile
                                                                            key={category}
                                                                            name={category}
                                                                            image={image}
                                                                            onClick={() => !loading && navigate(`/produtos/${category}`)}
                                                                            // noMargin='0'
                                                                            // imgSize='small'
                                                                        />
                                                                    ))}
                                                                </div>
                                                            }
                                                    </div>
                                                </>
                                            :
                                            <div className='center'>
                                                {internalID
                                                    ?
                                                        <div className='column center fadeInLong' style={{gap: '10px', color: 'gray'}}>
                                                            <h2>Nenhuma peça encontrada.</h2>
                                                        </div>

                                                        //Improved 'not found' message needs debuggin (appears before finishing loading products)
                                                        // <div className='column center fadeInLong' style={{gap: '10px'}}>
                                                        //     <h2>Não há peças compatíveis mapeadas para o veículo selecionado.</h2>
                                                        //     <span>Informe a peça que você precisa através do nosso atendimento:</span>
                                                        //     <Button
                                                        //         className='buttonColorGreen smallButton'
                                                        //         iconStart={<WhatsappIcon/>}
                                                        //         label='Falar com um atendente'
                                                        //         onClick={() => handleWhatsappClick(selectedBrand, selectedModel, selectedYear, selectedVersion)}
                                                        //         />
                                                        //     <span>Ou selecione outro veículo:</span>
                                                        //     <Button
                                                        //         className='buttonColorMain smallButton'
                                                        //         iconStart={<span><AddCar style={{color: 'white', position: 'relative', zIndex: 1}}/></span>}
                                                        //         label='Selecionar'
                                                        //         onClick={openVehicleSelect}
                                                        //     />
                                                        // </div>
                                                    :
                                                        <CircularProgress
                                                            style={{padding: '20px'}}
                                                            sx={{
                                                                color: 'var(--secondColor)'
                                                            }}
                                                        />
                                                }
                                            </div>
                                        }
                                    </>
                            }
                        </>
                    :
                        <div className='pageContent column center' style={{gap: '10px'}}>
                            <h2 style={{textAlign: 'center'}}>Selecione um veículo para ver peças compatíveis.</h2>
                            <Button
                                className='giantButton'
                                iconStart={<span><AddCar style={{color: 'white', position: 'relative', zIndex: 1}}/></span>}
                                label='Selecionar'
                                onClick={openVehicleSelect}
                            />
                        </div>
                }
            </div>
        </div>
    );
}

export default Categories;
